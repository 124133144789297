let resumeData = {
    "baseImageURL": "https://lakshay.dev/",
    "name": "Lakshay Verma",
    "role": "Web System Specialist",
    "linkedinId": "https://www.linkedin.com/in/lakshayverma/",
    "skypeId": "lk7253",
    "roleDescription": "Enabling the users with the power to compute with minimal efforts and achieve their end goals.",
    "endNote": "Tests whether in real life or programming, doesn't make us slow or weak. Instead they make path for us to become stronger and better than ever.",
    "socialLinks": [
        {
            "id": 1,
            "name": "linkedin",
            "url": "https://www.linkedin.com/in/lakshayverma/",
            "className": "fa fa-linkedin"
        },
        {
            "id": 2,
            "name": "github",
            "url": "https://github.com/lakshayverma",
            "className": "fa fa-github"
        },
        {
            "id": 3,
            "name": "twitter",
            "url": "https://twitter.com/lakshay__verma",
            "className": "fa fa-twitter"
        },
        {
            "id": 4,
            "name": "instagram",
            "url": "https://instagram.com/lakshay__verma",
            "className": "fa fa-instagram"
        },
        {
            "id": 5,
            "name": "blog",
            "url": "https://vermalakshay.wordpress.com/",
            "className": "fa fa-edit"
        }
    ],
    "aboutMe": "Working with Technology since 2010, I have achieved keen insights about how one system be it web, mobile or real-world should interact with another. Also my expertise is in ensuring that the goals are not just met but raised to even higher standards.",
    "address": "PB, IN",
    "website": "https://lakshay.dev",
    "education": [
        {
            "id": 4,
            "universityName": "I.G.N.O.U.",
            "specialization": "Masters in Computer Application",
            "dates": [
                {
                    "title": "Start",
                    "month": "Jul",
                    "year": "2014"
                },
                {
                    "title": "End",
                    "month": "Dec",
                    "year": "2017"
                },
            ],
            "achievements": [
                "Optimal system design with Web Technologies",
                "Interactive Games development with Unity 3d"
            ]
        },
        {
            "id": 3,
            "universityName": "G.N.D.U.",
            "specialization": "Bachelors in Computer Application",
            "dates": [
                {
                    "title": "Start",
                    "month": "Jul",
                    "year": "2011"
                },
                {
                    "title": "End",
                    "month": "Jul",
                    "year": "2014"
                },
            ],
            "achievements": [
                "Application Development with Java EE at its core",
                "UI design and development using Web Technologies HTML, CSS, jQuery",
                "UX design and development"
            ]
        }
    ],
    "work": [
        {
            "id": 4,
            "companyName": "Walkwel Technology Pvt. Ltd.",
            "specialization": "Web Development and Databases",
            "dates": [
                {
                    "title": "Start",
                    "month": "Sep",
                    "year": "2017"
                },
                {
                    "title": "",
                    "month": "",
                    "year": "Present"
                },
            ],
            "achievements": [
                "Server side Web applications using Laravel framework and PHP",
                "Frontend UI design using Angular, React and Laravel Blade",
                "System design and application deployment using AWS, Digital Ocean, GitHub Actions, Gitlab Pipelines and Docker",
                "User engagement campaigns and trigger analysis using Click Funnels and Infusionsoft"
            ]
        },
        {
            "id": 3,
            "companyName": "Club JB",
            "specialization": "Web Development",
            "dates": [
                {
                    "title": "Start",
                    "month": "May",
                    "year": "2017"
                },
                {
                    "title": "End",
                    "month": "Sep",
                    "year": "2017"
                },
            ],
            "achievements": [
                "API development (REST) using Laravel and Lumen",
                "User Journey design for the product",
                "System Analysis"
            ]
        },
        {
            "id": 2,
            "companyName": "Walnut Studios",
            "specialization": "Application and Game Development",
            "dates": [
                {
                    "title": "Start",
                    "month": "Apr",
                    "year": "2016"
                },
                {
                    "title": "End",
                    "month": "May",
                    "year": "2017"
                },
            ],
            "achievements": [
                "Mobile application design and development",
                "User engagement creation with Unity 3d",
                "API design with PHP"
            ]
        },
        {
            "id": 1,
            "companyName": "Advait SparX",
            "specialization": "Application and Game Development",
            "dates": [
                {
                    "title": "Start",
                    "month": "Jan",
                    "year": "2013"
                },
                {
                    "title": "End",
                    "month": "Dec",
                    "year": "2016"
                },
            ],
            "achievements": [
                "Desktop application development with Windows Toolkit",
                "User Interface Design to improvise experiences",
                "Application and System architecture design along with programming interfaces"
            ]
        }
    ],
    "skillsDescription": "Major Skills",
    "skills": [
        {
            "id": 1,
            "skillName": "Laravel",
            "percent": "80",
            "level": "advanced"
        },
        {
            "id": 2,
            "skillName": "Wordpress",
            "percent": "70",
            "level": "advanced"
        },
        {
            "id": 3,
            "skillName": "HTML + CSS",
            "percent": "90",
            "level": "high"
        },
        {
            "id": 5,
            "skillName": "JavaScript",
            "percent": "80",
            "level": "advanced"
        },
        {
            "id": 6,
            "skillName": "React",
            "percent": "80",
            "level": "high"
        },
        {
            "id": 7,
            "skillName": "Angular",
            "percent": "60",
            "level": "intermediate"
        },
        {
            "id": 8,
            "skillName": "Jest and RTL",
            "percent": "70",
            "level": "intermediate"
        },
        {
            "id": 9,
            "skillName": "Java",
            "percent": "60",
            "level": "moderate"
        },
        {
            "id": 10,
            "skillName": "GIT",
            "percent": "80",
            "level": "moderate"
        },
        {
            "id": 11,
            "skillName": "Server Management",
            "percent": "60",
            "level": "moderate"
        },
        {
            "id": 9,
            "skillName": "CI/CD",
            "percent": "60",
            "level": "moderate"
        },
        {
            "id": 12,
            "skillName": "Adobe Photoshop",
            "percent": "55",
            "level": "moderate"
        },
        {
            "id": 13,
            "skillName": "Android",
            "percent": "40",
            "level": "moderate"
        },
        {
            "id": 14,
            "skillName": "Flutter Dart",
            "percent": "40",
            "level": "beginner"
        }
    ],
    "portfolioDescription": "These are some of the jobs building my Portfolio Strong.",
    "portfolio": [
        {
            "id": 9,
            "name": "Transport Management System",
            "description": "A complete solution to manage the transport and load journey from warehousing to quotes and then actual delivery with tracking of the load.",
            "imgurl": "../images/lms.png",
            "stats": [
                "Java",
                "ReactJS",
                "Jest",
                "Enzyme",
                "RTL",
                "Jenkins",
                "i18n",
                "SCSS"
            ],
            "achievements": [
                "Handling and providing mentorship to the team of about 15.",
                "Reviewing code to detect early signs of security issues",
                "Designing interfaces to improve user interactions",
                "Providing support and guidelines for new features"
            ]
        },
        {
            "id": 8,
            "name": "InfusionSoft Content Management",
            "description": "A progressive web application to manage learning content from a single and unified admin panel that links with a popular and leading Customer Relation Manager. The products are allowed to be accessed only by customer roles assigned to them in the CRM which allows targetted mails to allow controlled content access.",
            "imgurl": "../images/lms.png",
            "stats": [
                "Node",
                "Mongo",
                "Angluar",
                "Shell",
                "Ionic",
                "GitLab CI",
                "Jest",
                "SCSS"
            ],
            "achievements": [
                "Formulating User campaigns for maximum conversion rates",
                "Designing APIs to interact with various CRMs",
                "Ensuring that the page meets Search Engine guidelines",
                "Enforcing Web Vitals are met"
            ]
        },
        {
            "id": 6,
            "name": "Heart Monitoring and Reporting",
            "description": "A Laravel based application with ReactJS as its frontend to allow Cardiac Centers to generate reports where all 64 heart zones are covered to get predictive analysis done and keep track of a patient's condition through out the years.",
            "imgurl": "../images/lms.png",
            "stats": [
                "PHP",
                "Laravel",
                "Babel",
                "Node",
                "Nginx",
                "Apache",
                "ReactJS",
                "SCSS"
            ],
            "achievements": [
                "Managing team of React and Laravel developers",
                "Designing Servers with Dynamic Routing and Domains",
                "Enforcing GDPR"
            ]
        },
        {
            "id": 7,
            "name": "Music Licensing System",
            "description": "Wordpress as an CMS at its best, using NextJS as the frontend to allow better SEO for entire site with dynamic sitemaps. Also keeping most of the components alive during page loads.",
            "imgurl": "../images/lms.png",
            "stats": [
                "PHP",
                "Wordpress",
                "NextJS",
                "Github Actions",
                "AWS EC2",
                "ReactJS",
                "Analytics",
                "SCSS"
            ],
            "achievements": [
                "Custom Content management system based on Wordpress to link with a NextJS application",
                "Realtime licensing for purchased tracks",
                "Dynamic playlists to match user needs",
                "Custom routing to link between Server Side and Client Side renders"
            ]
        },
        {
            "id": 1,
            "name": "Learning Management System",
            "description": "Laravel Application that offers complete suite of Student to Trainer interaction towards course completion.",
            "imgurl": "../images/lms.png",
            "stats": [
                "PHP",
                "Laravel",
                "Wordpress",
                "Shell",
                "Apache",
                "Route53",
                "Webpack",
                "SCSS"
            ],
            "achievements": [
                "Optimal system design with Web Technologies",
                "Designed database to introduce multiple courses",
                "APIs to track and record quiz and exam data"
            ]
        },
        {
            "id": 2,
            "name": "Amaze Yourself",
            "description": "Mobile application based on a concept that amazes.",
            "imgurl": "https://blog.lakshay.dev/wp-content/uploads/2015/11/amaze-your-self-logo.png",
            "stats": [
                "Photoshop",
                "Android",
                "Unity 3d",
                "Java"
            ],
            "achievements": [
                "Interactive Games development with Unity 3d",
                "Designed User Interface and various Graphical elements"
            ]
        },
        {
            "id": 3,
            "name": "MarG",
            "description": "Unity Mobile Game where users need to navigate through a series of mazes to reach goal.",
            "imgurl": "https://blog.lakshay.dev/wp-content/uploads/2015/11/marg-logo.png",
            "stats": [
                "Unity 3d",
                "Blender",
                "Photoshop"
            ],
            "achievements": [
                "Provide support for creating 3d models",
                "Designed 3d levels in form of puzzles",
                "Interactive Games development with Unity 3d"
            ]
        },
        {
            "id": 4,
            "name": "Baani Nitnem",
            "description": "Mobile application that helps in saying daily prayers.",
            "imgurl": "https://blog.lakshay.dev/wp-content/uploads/2015/11/baani-nitnem-wide.png",
            "stats": [
                "C#",
                "Photoshop",
                "Java"
            ],
            "achievements": [
                "Designed User interface",
                "Ensured that the end users does not strain their eyes"
            ]
        },
        {
            "id": 5,
            "name": "Birdy Blue",
            "description": "Unity Mobile Game.",
            "imgurl": "https://blog.lakshay.dev/wp-content/uploads/2015/11/birdy-blue-wide.png",
            "stats": [
                "Photoshop",
                "Unity 3d"
            ],
            "achievements": [
                "Interactive Games development with Unity 3d"
            ]
        }
    ],
    "testimonials": [
        {
          "id": 5,
          "name": "Undisclosed Q",
          "description": "Lakshay is a key member of the team. He has helped ensure project success on every ticket he is assigned. He has learned the system very well and has established good processes for improvement. He is appreciated for his dedication and commitment. He is a good team player and mentor for others on the team."
        },
        {
            "id": 4,
            "name": "Hollywood Cues",
            "description": 'Additionally, we feel that Lakshay is open-minded and agile in his approach. Not only does he brings his own ideas and experience to the table, but he listens and is able to change direction if asked to do so. He also "gets things done" when it comes to managing the rest of the Walkwel team.'
        },
        {
            "id": 1,
            "name": "Pawanjot Singh",
            "description": "Loved the application, Baani Nitnem. Helps me keep track of my daily prayers routine"
        },
        {
            "id": 2,
            "name": "Simran Singh Sandhu",
            "description": "Awesome application, you nailed the graphics for the birdy. Keep going forward."
        },
        {
            "id": 3,
            "name": "Rachna Naaz",
            "description": "The site speaks to me with such a soothing color and font selection. You did a great job, I am so humbled."
        }
    ],
    "interestsDescription": "While not working on Jobs, I take my sweet time to enjoy the little pleasures of Life.",
    "interests": [
        {
            "id": 1,
            "name": "Music",
            "description": "Listening to the mesmerizing notes keeps you going on and on through the journey.",
            "stats": [
                "Soothing",
                "Gentle",
                "Empowering",
                "Astounding"
            ]
        },
        {
            "id": 2,
            "name": "Anime",
            "description": "Anime is not cartoon, but entry to a world full of surprises and learnings.",
            "stats": [
                "Mystery",
                "Suspense",
                "Magic",
                "New World"
            ]
        },
        {
            "id": 3,
            "name": "Socializing",
            "description": "Keeping in touch with others is a good start for a healthy life.",
            "stats": [
                "Jokes",
                "Guidance",
                "Support"
            ]
        },
        {
            "id": 7,
            "name": "Rubik's Cube",
            "description": "Deemed one of the most colorful puzzels of all time, I love working my way through the standard 3x3 Cube.",
            "stats": [
                "Beauty",
                "Machenism",
                "Appeal",
                "Logic",
                "Analytics"
            ]
        },
        {
            "id": 6,
            "name": "Chess",
            "description": "A decent game of chess with peers makes me going. Though I am not an expert in the game, still 8x8 board has something about it.",
            "stats": [
                "Reasoning",
                "Rook",
                "Logic",
                "Beauty",
                "Bishop",
                "Concept",
                "Asthetics",
                "Knight"
            ]
        }
    ],
}

export default resumeData
